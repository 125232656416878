/* .App {
  
}  */

/* Basic Elements */
html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: 'Source Sans Pro';
  background-size: 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-color: #C5B9A9;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  min-width: 100%;
  min-height: 100vh;
  font-size: 16px;
  overflow-x: hidden;
}

#frame {
  padding: 40px 0;
}

#main {
  overflow: hidden;
  margin: 0 auto;
  width: 80%;
  max-width: 960px;
  background: #fff;
}

@media (max-width: 720px) {
  #main {
    width: 90%; 
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

h2,
h3 {
  font-weight: normal;
}

p {
  margin: 0 0 1.4em 0;
}

select {
  font-size: 16px;
  width: 40%;
  padding: 4px;
}

.chip-photo {
  position: absolute;
  top: 25%;
  left: 30px;
  max-width: 27%;
  object-fit: cover;
}

.text-center {
  text-align: center;
}

.title-case {
  text-transform: capitalize;
}

/* Columns */
.one-third-column {
  float: left;
  width: 33.33%;
}

.two-third-column {
  float: left;
  width: 66.66%;
}

@media (max-width: 720px) {
  .one-third-column {
    float: none;
    width: 100%;
  }

  .two-third-column {
    float: none;
    width: 100%;
  }
}



/* App Layout */
.header {
  /* height: 90px; */
  /* padding: 20px 40px; */
  text-align: center;
}

.introduction-row {
  position: relative;
  display: table;
  min-height: 8em;
  width: 100%;
  padding: 20px 40px;
  /* background-image: url('./assets/bucuti-beach.jpg');
  background-repeat: no-repeat;
  background-size: cover; */
  /* background-position: bottom; */
  background: rgb(110,0,0);
  background: linear-gradient(180deg, rgba(110,0,0,1) 0%, rgba(163,0,0,1) 100%);
}

.bar-row {
  /* background-image: url('./assets/sita-blue.jpeg'); */
  background-size: cover;
  padding: 20px 40px;
}

.content-row {
  background: white;
  padding: 20px 40px;
  overflow: hidden;
}

.footer {
  position: relative;
  font-size: calc(10px + 0.390625vw);
  /* background-image: url('./assets/sita-blue.jpeg'); */
  background-size: cover;
  padding: 0 40px;
}



/* Special Styles */
.introduction-row {

}
  .introduction-row h1 {
    font-size: calc(30px + 0.390625vw);
    color: #fff;
  }
  .introduction-row h1.text-center-vert {
    margin-top: 8vh;
  }
  .introduction-row p {
    color: #fff;
  }

.title-text {
  color: white;
  font-size: calc(30px + 0.390625vw);
  width: 100%;
  padding: 0px;
  vertical-align: middle;
  margin-bottom: 0.6em;
}

.title-center-text {
  color: white;
  font-size: calc(30px + 0.390625vw);
  width: 100%;
  margin-top: 1.9em;
}

.title-success-text {
  color: white;
  font-size: calc(30px + 0.390625vw);
  width: 100%;
  margin-top: 0.6em;
}

.title-explain-text {
  color: white;
  font-size: calc(14px + 0.390625vw);
  width: 100%;
  padding: 0px;
}

.bar-text {
  color: white;
  font-weight: bold;
  font-size: calc(14px + 0.390625vw);
}

.bar-text {
  color: white;
  font-size: calc(12px + 0.390625vw);
  margin-left: 5px;
}

.content-row p {
  color: black;
  font-size: calc(15px + 0.390625vw);
  width: 100%;
}
  .content-row .info-text {
    font-size: calc(12px + 0.390625vw);
  }

.passport-div {
  position: relative;
  margin: 0 auto;

  /* min-height: 256px; */
  height: 51.3vmin;
  max-height: 513px; 
  
  /* min-width: 360px; */
  width: 72vmin;
  max-width: 720px;
  
  font-size: 1.8vmin;
  text-transform: uppercase;

  background-image: url('./assets/atlantis-passport.png');
  background-repeat: no-repeat;
  background-size: cover;
}

/* Passport Fields */
.passport-type {
  position: absolute;
  top: 18%;
  left: 35.75%;
}
.passport-country-code {
  position: absolute;
  top: 18%;
  left: 48.25%;
}
.passport-number {
  position: absolute;
  top: 18%;
  left: 70%;
}
.passport-surname {
  position: absolute;
  top: 27.5%;
  left: 35.75%;
}
.passport-given-names {
  position: absolute;
  top: 34.5%;
  left: 35.75%;
}
.passport-nationality {
  position: absolute;
  top: 42.5%;
  left: 35.75%;
}
.passport-birth-date {
  position: absolute;
  top: 50%;
  left: 35.75%;
}
.passport-birth-place {
  position: absolute;
  top: 57.5%;
  left: 35.75%;
}
.passport-sex {
  position: absolute;
  left: 70%;
  top: 57.5%;
}
.passport-issue-date {
  position: absolute;
  top: 65%;
  left: 35.75%;
}
.passport-expiry-date {
  position: absolute;
  top: 72.25%;
  left: 35.75%;
}
.passport-authority {
  position: absolute;
  top: 65%;
  left: 70%;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  /* position: relative;
  display: inline-block; */
  font-size: 16px;
  width: 40%;
  padding: 4px;
}

.spacing {
  margin-top: 20px;
}
